import { useAuth0 } from '@auth0/auth0-react';
import { ChevronRight } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logoVam from '../../assets/logo-vam.svg';
import { useAppSelector } from '../../hooks';
import { selectUser } from '../../services/store/userSlice';
import { getMyVamName } from '../../utils/functions/user';
import { Footer } from '../layout/Footer/Footer';
import { VamCard } from '../shared';
import styles from './Welcome.module.scss';

const VamServices = () => {
  const { t } = useTranslation('home');
  return (
    <div className="tw-mx-32">
      <div className="tw-mt-12 tw-mb-8">
        <Typography component={'div'}>
          <div className="tw-text-4xl tw-flex">
            {t('vam')}
            {Ricon('tw-leading-3', 'tw-text-xs')} &nbsp;{t('services')}
          </div>
        </Typography>
      </div>
      <div className={`${styles.welcomeWordWideText} tw-leading-8`}>
        <span>
          {t('welcome.text_part1')} <br />
          {t('welcome.text_part2')}
        </span>
      </div>
    </div>
  );
};

const VamCards = () => {
  const { t } = useTranslation('home');
  const docContent = () => {
    return (
      <ul className="tw-leading-7">
        <li>&gt; {t('documentation.content.find_all')}</li>
        <li>&gt; {t('documentation.content.search')}</li>
        <li>
          &gt; {t('documentation.content.access')}
          <ul>
            <li>&bull; {t('documentation.content.tsli')}</li>
            <li>&bull; {t('documentation.content.rtli')}</li>
            <li>&bull; {t('documentation.content.drawings')}</li>
            <li>...</li>
          </ul>
        </li>
      </ul>
    );
  };

  const GaugingContent = () => {
    return (
      <ul className="tw-leading-7">
        <li>&bull; {t('gauge.content.rent')}</li>
        <li>&bull; {t('gauge.content.shortTerm')}</li>
      </ul>
    );
  };
  return (
    <div className="bg-color-white">
      <div className="tw-pb-8">
        <div className="tw-px-32 tw-my-8">
          <Typography component={'div'}>
            <div className="tw-text-4xl tw-py-8 tw-flex">
              My VAM{Ricon('tw-leading-3', 'tw-text-xs')}
            </div>
          </Typography>
          <div
            className={`${styles.welcomeVamCard} tw-grid tw-grid-rows-2 tw-grid-cols-2 tw-px-32`}
          >
            <VamCard title={t('documentation.title')} functionComponent={docContent}></VamCard>
            <VamCard
              title={t('licence.title')}
              text={t('licence.content')}
              functionComponent={() => (
                <li>
                  <span className="tw-text-base">• Plant Royalties</span>
                </li>
              )}
            ></VamCard>
            <VamCard title={t('gauge.title')} functionComponent={GaugingContent}></VamCard>
            <VamCard title={t('training.title')} text={t('training.content')}></VamCard>
          </div>
        </div>
      </div>
    </div>
  );
};

const Ricon = (divClass: string, spanClass: string) => {
  return (
    <div className={divClass}>
      <span className={spanClass}>®</span>
    </div>
  );
};

export function Welcome() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const userDetails = useAppSelector(selectUser);
  const { t } = useTranslation('home');
  // clear history
  window.history.replaceState({}, document.title);

  const handleLogin = async () => {
    await loginWithRedirect();
  };

  const SignInOut = () => {
    return isAuthenticated ? (
      <span
        className={styles.welcomeAClass}
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin + '/' } })}
      >
        {t('hello')}, {userDetails ? getMyVamName(userDetails) : '...'}
      </span>
    ) : (
      <span className={styles.welcomeAClass} onClick={handleLogin}>
        {t('signin')}
      </span>
    );
  };

  return (
    <div>
      <div className={`${styles.welcome} tw-text-white`}>
        <div className="tw-p-8 tw-flex tw-justify-between">
          <div className="tw-flex w100">
            <Link to="/" className="tw-mt-3">
              <img src={logoVam} alt="logo-vam" data-testid="logo-vam" />
            </Link>
            <nav className={`w100 tw-items-center ${styles.welcomeNavbar}`}>
              <div className="tw-flex tw-justify-end">
                <div className={styles.welcomeSignIn}>{SignInOut()}</div>
              </div>
            </nav>
          </div>
          <div></div>
        </div>
        <div className="tw-pt-32 tw-pl-36 tw-font-medium">
          <div className="tw-text-6xl">{t('welcome.title')}</div>
          <div className="tw-flex">
            <div className="tw-text-6xl tw-mt-4">{t('to_myvam')}</div>
            {Ricon('tw-mt-4', 'tw-text-3xl')}
          </div>
          <div className="tw-flex tw-items-center tw-mt-4 tw-cursor-pointer">
            <a className="color-white tw-no-underline" href="#0">
              {t('discover')}
            </a>
            <ChevronRight className="tw-m-4"></ChevronRight>
          </div>
        </div>
      </div>
      {VamServices()}
      {VamCards()}
      <Footer />
    </div>
  );
}
