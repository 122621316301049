import styled from '@emotion/styled';
import { FormControl, InputLabel, TextFieldProps } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { $primaryBrand, $primaryWhite } from '../../../styles/colors';
import { TextFieldVariant } from '../../../utils';
import { addTimeToDate } from '../../../utils/functions';

type UnwantedKeys = 'onChange' | 'renderInput' | 'value';

type FormInputProps = {
  name: string;
  disabled?: boolean;
  handleChange?: Function;
  dataTestId?: string;
  inputLabel?: string;
} & Omit<DatePickerProps<any>, UnwantedKeys> &
  TextFieldProps;

const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  position: relative;
  transform: none;
  text-transform: uppercase;
`;

export const FormDatePicker: FC<FormInputProps> = ({
  name,
  variant,
  disabled,
  handleChange,
  dataTestId,
  inputLabel,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl fullWidth>
      {inputLabel ? (
        <StyledInputLabel
          sx={{
            color: $primaryWhite,
          }}
          data-testid={`${name}-test-id`}
          focused={false}
          id={inputLabel}
        >
          {inputLabel}
        </StyledInputLabel>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => {
          return (
            <DatePicker
              id={inputLabel}
              label={otherProps.label}
              onChange={(date) => {
                const updatedDate = addTimeToDate(date);
                handleChange && handleChange(updatedDate, name);
                return onChange(updatedDate);
              }}
              value={value ? new Date(value) : value}
              slotProps={{
                textField: {
                  helperText: (errors[name] ? errors[name]?.message : '') as any,
                  variant: variant || TextFieldVariant.Standard,
                  error: !!errors[name],
                  InputProps: { 'data-testid': dataTestId },
                },
                desktopPaper: {
                  sx: {
                    '& .MuiPickersDay-root': {
                      '&.Mui-selected': {
                        backgroundColor: $primaryBrand,
                      },
                    },
                  },
                },
              }}
              format="dd/MM/yyyy"
              disabled={disabled ? disabled : false}
              {...(otherProps as any)}
            />
          );
        }}
      />
      <FormHelperText className="color-error-red">
        {(errors?.name ? errors[name]?.message : '') as any}
      </FormHelperText>
    </FormControl>
  );
};
