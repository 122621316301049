import { Currency } from '../constants';

export function computeTotalPrice(
  withBident: boolean,
  totalPrice: number,
  bidentPrice: number
): number {
  return withBident ? totalPrice + bidentPrice : totalPrice - bidentPrice;
}

export function formatPriceLocaleAware(price: number, currency: Currency): string {
  let locale: string;

  switch (currency) {
    case Currency.EUR:
      locale = 'fr-FR';
      break;
    case Currency.GBP:
      locale = 'en-GB';
      break;
    case Currency.JPY:
      locale = 'ja-JP';
      break;
    case Currency.MXN:
      locale = 'es-MX';
      break;
    case Currency.BRL:
      locale = 'pt-BR';
      break;
    case Currency.CAD:
      locale = 'en-CA';
      break;
    case Currency.CNY:
      locale = 'zh-CN';
      break;
    default:
      locale = 'en-US';
      break;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || Currency.USD,
  }).format(price);
}
