import { InformationBox, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { useTranslation } from 'react-i18next';

import { $primaryBlack } from '../../styles/colors';
import { SUBTOTAL_KEY, SUBTOTAL_VALUE_KEY } from './utils';

interface SubtotalInvoiceProps {
  subTotalPrice: number;
  color?: string;
}

export function SubTotalInvoice({ subTotalPrice, color }: SubtotalInvoiceProps) {
  const { t } = useTranslation('invoice');
  return (
    <InformationBox color={color || $primaryBlack}>
      <div className="tw-flex tw-w-full tw-justify-between tw-h-6 tw-items-center">
        <Typo
          className="tw-whitespace-nowrap"
          variant="labelForStatus"
          fontWeight={500}
          fontStyle="italic"
          color={$primaryBlack}
        >
          {t(SUBTOTAL_KEY)}
        </Typo>
        <Typo
          className="tw-whitespace-nowrap"
          variant="labelForStatus"
          fontWeight={500}
          fontStyle="normal"
          color={$primaryBlack}
        >
          {t(SUBTOTAL_VALUE_KEY, { value: subTotalPrice })}
        </Typo>
      </div>
    </InformationBox>
  );
}
