import { CircleStatus, SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useAppSelector } from '../../../hooks';
import { HttpVerbs } from '../../../interfaces/api';
import {
  SetDetailsManagement,
  SetStateBoxProps,
  SetsCreationManagementFilter,
  SetsCreationManagementParams,
  SetsManagementGrid,
} from '../../../interfaces/set-creation';
import { exportCsvSets, getSetsCreationManagement } from '../../../services/api/set-creation-api';
import { getShippingReturnInformations, saveReturn } from '../../../services/api/shipping-api';
import { selectUser } from '../../../services/store/userSlice';
import { $darkGreen } from '../../../styles/colors';
import {
  DEFAULT_PAGINATION_MODEL,
  HYPERMEDIA_NAME,
  OD_MAPPING,
  SET_AVAILABILITY_STATUS_COLOR,
  SET_STATE_GRID_MAPPING,
  SetAvailabilityStatus,
  VegWebsiteStatus,
} from '../../../utils';
import {
  formatDate,
  getDateFromString,
  getDesignationProductWithSetId,
  getHrefHypermedia,
  getSetState,
  mapToProductFilterFromArrays,
  removeFalsyPropertyFromArrays,
} from '../../../utils/functions';
import { redirectTo } from '../../../utils/functions/helpers';
import { mapManuallyReturn } from '../../booking/utils';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { columnRenderHeader } from '../../data-grid/utils/functions';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import {
  BoxContainer,
  CountResultFilter,
  MenuOptionsProps,
  SetStateBox,
  VamFilter,
  VamMoreOptions,
  VamPageTitle,
} from '../../shared';
import { SetsManagementFilter } from './SetsManagementFilter';
import { FormSchemaType, defaultValues, formSchema } from './SetsManagementFilterForm';
import ShowApplicabilities from './ShowApplicabilities';

const hideColumns = {
  // Hide columns status and traderName, the other columns will remain visible
  modification_date: false,
  modified_by: false,
  creation_date: false,
  creation_by: false,
  calibration_site: false,
};

export function SetsManagement() {
  const { t } = useTranslation('gauge', { keyPrefix: 'setsManagement' });
  const user = useAppSelector(selectUser);
  const { t: tGauge } = useTranslation('gauge');
  const { t: tCommon } = useTranslation('common');
  const [sets, setSets] = React.useState<SetsManagementGrid[]>([]);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [count, setCountSize] = React.useState(0);
  const [loadingGrid, setLoadingGrid] = React.useState(false);
  const [openApplicabilities, setOpenApplicabilities] = React.useState(false);
  const [hrefApplicabilities, setHrefApplicabilities] = React.useState<string>();
  const [sortModel, setSortModel] = React.useState([]);
  const [datafilter, setDataFilter] = React.useState<SetsCreationManagementFilter>({
    location_id: user.depotId ? [user.depotId] : [],
  });

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit } = methods;
  const navigate = useNavigate();
  const location = useLocation();

  const i18Key = 'actions.confirmReturn.title';

  /* istanbul ignore next */
  const handleConfirmReturn = async (row) => {
    if (
      row.availability_status === SetAvailabilityStatus.AtLicensee ||
      row.availability_status === SetAvailabilityStatus.ReturnOngoing
    ) {
      const { data } = await getShippingReturnInformations([row.id]);
      const setInformations = data?.[0];
      // status is At Licensee so simulate return from licensee and redirect to
      // confirm return
      if (row.availability_status === SetAvailabilityStatus.AtLicensee) {
        const vegData = mapManuallyReturn(setInformations, user.idUserMyVam);
        const designation = getDesignationProductWithSetId(row.id, row);
        Swal.fire({
          icon: 'warning',
          title: t(i18Key, { context: 'valid' }),
          html: t('actions.confirmReturn.notification', { designation, context: 'valid' }),
          showCancelButton: true,
          confirmButtonText: t('actions.confirmReturn.confirmBtn'),
          confirmButtonColor: $darkGreen,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const { data: shippingId } = await saveReturn(vegData);
            const serializedShipment = {
              id: shippingId,
              included_bookings: [setInformations.booking_id],
              licensee_number: setInformations.licensee_number,
            };
            redirectTo('../../manage-shipments/confirm-return', navigate, {
              state: {
                from: location.pathname,
                currentSelectedShipment: serializedShipment,
              },
            });
          }
        });
        // status is Return Ongoing so only redirect to confirm return page
      } else {
        const serializedShipment = {
          id: row.shipping_id,
          included_bookings: [setInformations.booking_id],
          licensee_number: setInformations.licensee_number,
        };
        redirectTo('../../manage-shipments/confirm-return', navigate, {
          state: {
            from: location.pathname,
            currentSelectedShipment: serializedShipment,
          },
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: t(i18Key, { context: 'invalid' }),
        html: t('actions.confirmReturn.notification', {
          status: tGauge(`setCreation.setAvailabilityStatus.${row.availability_status}`),
          context: 'invalid',
        }),
      });
    }
  };

  /* istanbul ignore next */
  const optionsMenu: MenuOptionsProps[] = [
    {
      label: t('actions.seeApplicabilities'),
      handleItemClick: (_, __, row) => {
        const href = getHrefHypermedia(
          row.actions,
          HYPERMEDIA_NAME.setCreationApplicabilities,
          HttpVerbs.GET
        );
        setHrefApplicabilities(href);
        setOpenApplicabilities(true);
      },
    },
    {
      label: t(i18Key, { context: 'valid' }),
      handleItemClick: (_, __, row) => {
        handleConfirmReturn(row);
      },
    },
  ];

  /* istanbul ignore next */
  const cols: GridColDef[] = [
    {
      field: 'id',
      headerName: 'SET#',
      flex: 0.5,
    },
    {
      field: 'connection',
      headerName: 'CONNECTION',
      flex: 1.25,
    },
    {
      field: 'od_inch',
      headerName: 'DIAMETER',
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<any, number>) =>
        OD_MAPPING?.[params.value] || params.value,
    },
    {
      field: 'min_weight_max_weight',
      headerName: 'WEIGHT',
      flex: 1.5,
    },
    {
      field: 'end',
      headerName: 'END',
      flex: 1,
    },
    {
      field: 'application',
      headerName: 'APPLICATION',
      flex: 1.5,
    },
    {
      field: 'option',
      headerName: 'OPTION',
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'LOCATION',
      flex: 1,
    },
    {
      field: 'calibration_site',
      renderHeader: () => columnRenderHeader('CALIBRATION', 'SITE'),
      flex: 1,
    },
    {
      field: 'website_status',
      renderHeader: () => columnRenderHeader('WEB', 'STATUS'),
      renderCell: (params: GridRenderCellParams<any, VegWebsiteStatus>) => (
        <CircleStatus variant={params.value.toLowerCase() as any} />
      ),
      flex: 1,
    },
    {
      field: 'set_state',
      headerName: 'SET STATE',
      flex: 1,
      renderCell: ({ value }: GridRenderCellParams<SetStateBoxProps>) => (
        <SetStateBox
          isComplete={value.isComplete}
          nextCalibrationDate={value.nextCalibrationDate}
        />
      ),
      align: 'center',
      sortable: false,
    },
    {
      field: 'availability_status',
      headerName: 'STATUS',
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, SetAvailabilityStatus>) => (
        <Typo
          variant="body2"
          color={SET_AVAILABILITY_STATUS_COLOR[params.value]}
          fontWeight={500}
          className="text-ellipsis tw-inline-flex"
        >
          {tGauge(`setCreation.setAvailabilityStatus.${params.value}`)}
        </Typo>
      ),
    },
    {
      field: 'modification_date',
      renderHeader: () => columnRenderHeader('MODIFICATION', 'DATE'),
      flex: 1,
    },
    {
      field: 'next_set_calibration_date',
      renderHeader: () => columnRenderHeader('NEXT CALIB.', 'DATE'),
      flex: 1,
    },
    {
      field: 'modified_by',
      renderHeader: () => columnRenderHeader('MODIFIED', 'BY'),
      flex: 1,
    },
    {
      field: 'creation_date',
      renderHeader: () => columnRenderHeader('CREATION', 'DATE'),
      flex: 1,
    },
    {
      field: 'creation_by',
      renderHeader: () => columnRenderHeader('CREATED', 'BY'),
      flex: 1,
    },
    {
      field: 'action',
      align: 'center',
      flex: 0.5,
      renderHeader: () => <></>,
      renderCell: ({ row }) => {
        return <VamMoreOptions options={optionsMenu} row={row} />;
      },
      sortable: false,
      hideable: false,
    },
  ];

  useEffect(() => {
    const fetchData = async (params: SetsCreationManagementParams) => {
      fetchGetSetsCreation(params);
    };
    const skipPage = paginationModel.page * paginationModel.pageSize;

    fetchData({
      data_filter: datafilter,
      pagination: { skip: skipPage, limit: paginationModel.pageSize },
      sort: sortModel,
    });
  }, [paginationModel.page, sortModel]);

  const redirectToUpdateSet = async (row: SetDetailsManagement) => {
    const { id } = row;
    redirectTo(`${id}`, navigate, {
      state: { from: location },
    });
  };

  const fetchGetSetsCreation = (params: SetsCreationManagementParams) => {
    setLoadingGrid(true);
    getSetsCreationManagement(params)
      .then(({ data }) => {
        const dataGrid = data?.sets?.map((row, idx) => {
          const setIs = getSetState(
            row.is_complete,
            getDateFromString(row.next_set_calibration_date),
            tGauge
          );
          return {
            ...row,
            creation_date: formatDate(row.creation_date, false),
            next_set_calibration_date: formatDate(row.next_set_calibration_date, false),
            modification_date: formatDate(row.modification_date, false),
            set_state: {
              nextCalibrationDate: getDateFromString(row.next_set_calibration_date),
              isComplete: row.is_complete,
            },
            grid_status: SET_STATE_GRID_MAPPING[setIs.status],
          } as SetsManagementGrid;
        });
        setCountSize(data.count);
        setSets(dataGrid);
      })
      .catch((error) => {
        console.error('🚀 ~ file: SetsManagement.tsx ~ fetch ~ error', error);
      })
      .finally(() => {
        setLoadingGrid(false);
      });
  };

  const applyFilter: SubmitHandler<FormSchemaType> = (data) => {
    const filter = removeFalsyPropertyFromArrays(
      mapToProductFilterFromArrays(data as Record<string, any[]>, [
        'location_id',
        'website_status',
        'set_state',
        'availability_status',
        'set_ids',
      ]) as Record<string, any[]>
    );
    setDataFilter(filter);
    const skipPage = paginationModel.page * paginationModel.pageSize;
    fetchGetSetsCreation({
      data_filter: filter,
      pagination: { skip: skipPage, limit: paginationModel.pageSize },
      sort: sortModel,
    });
  };

  const downloadCSV = async () => {
    exportCsvSets({
      data_filter: datafilter,
      pagination: { skip: 0, limit: count },
      sort: sortModel,
    })
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const filename = 'export_sets_results.csv';
        saveAs(blob, filename);
        enqueueSnackbar(tCommon('notification.successExport'), {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <ShowApplicabilities
        isOpen={openApplicabilities}
        setIsOpen={setOpenApplicabilities}
        hrefUrl={hrefApplicabilities}
      />
      <Stack direction="row" justifyContent="space-between">
        <VamPageTitle
          subTitle={tGauge('tag')}
          title={t('title')}
          breadcrumbRoutes={[
            {
              breadcrumb: 'Sets Management',
              path: '#',
            },
          ]}
        />
      </Stack>
      <FormProvider {...methods}>
        <VamFilter
          title={t('titleFilter')}
          subTitle={t('subTitleFilter')}
          handleApplyFilter={handleSubmit(applyFilter)}
          disableApplyBtn={loadingGrid}
        >
          <SetsManagementFilter />
        </VamFilter>
      </FormProvider>
      <BoxContainer>
        <Stack
          className="tw-pb-8"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <CountResultFilter className="tw--ml-4" count={count} />
          <Stack direction="row" spacing={2}>
            <SimpleButton
              variant="secondary-dark"
              data-testid="btn-csv-download-sets-management"
              onClick={() => downloadCSV()}
            >
              {tCommon('downloadResults')}
            </SimpleButton>
            <SimpleButton
              variant="text"
              data-testid="btn-add-new-set"
              onClick={() => redirectTo('../create', navigate)}
            >
              {t('addNewSet')}
            </SimpleButton>
          </Stack>
        </Stack>
        <VamDataGrid
          sx={{
            '.MuiBox-root': {
              height: 'auto',
            },
          }}
          data-testid="grid-sets-management"
          initialStateColumns={{
            columnVisibilityModel: hideColumns,
          }}
          columns={cols}
          rows={sets}
          height={500}
          disableColumnFilter
          disableRowSelectionOnClick
          showRowLeftThickBorder
          paginationMode="server"
          loading={loadingGrid}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={/* istanbul ignore next */ setPaginationModel}
          rowCount={count}
          onRowDoubleClick={({ row }: GridRowParams) => {
            /* istanbul ignore next */
            redirectToUpdateSet(row);
          }}
          sortingMode="server"
          onSortModelChange={
            /* istanbul ignore next */ (sortModel: GridSortModel) => {
              setPaginationModel({ ...paginationModel, page: 0 });
              setSortModel(sortModel);
            }
          }
          sortModel={sortModel}
        />
      </BoxContainer>
    </ContainerOutlet>
  );
}
