import { InformationBox, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactElement } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InvoiceGrid } from '../../interfaces/invoice';
import { $primaryBrand, $primaryNavy } from '../../styles/colors';
import { Currency, LTR_SIX_MONTHS_DAYS, RentalType } from '../../utils';
import { formatWithFixedDecimals } from '../../utils/functions/numbers';
import { formatPriceLocaleAware } from '../../utils/functions/price';
import { columnRenderHeader } from '../data-grid/utils/functions';
import { RentalTypeNode } from '../shared';

export const SUBTOTAL_VALUE_KEY = 'invoiceDetails.billing.subtotalValue';
export const SUBTOTAL_KEY = 'invoiceDetails.billing.subtotal';

interface UpdateFields {
  admin_charges?: number;
  fuel_charges?: number;
  damage_surcharge?: number;
  missing_gauge_surcharge?: number;
  price_per_day?: number;
  export_transport_charges?: number;
  import_transport_charges?: number;
  bident_price?: number;
}

export function applyUpdateInvoiceFields(
  setValue: UseFormSetValue<FieldValues>,
  values?: UpdateFields
): void {
  [
    'admin_charges',
    'fuel_charges',
    'damage_surcharge',
    'missing_gauge_surcharge',
    'price_per_day',
    'bident_price',
  ].forEach((field) => {
    const value = values?.[field] ? formatWithFixedDecimals(values?.[field], 2) : 0;
    setValue(field, value);
  });
}

export function handleSwitchRentalType(
  setValue: UseFormSetValue<FieldValues>,
  rental_type: string,
  switched_to_ltr: boolean,
  trigger: Function
): void {
  setValue('rental_type', rental_type);
  setValue('switched_to_ltr', switched_to_ltr);
  setValue('days_in_hiring', LTR_SIX_MONTHS_DAYS);
  trigger('rental_type');
}

export function AddBidentPrice({ invoice }: { invoice: InvoiceGrid }): ReactElement {
  const { t } = useTranslation('invoice');

  return invoice.rental_type ? (
    <div className="tw-flex tw-flex-col -tw-mt-2">
      <InformationBox color={$primaryBrand}>
        <div className="tw-flex tw-w-full tw-justify-between tw-h-8 tw-items-center">
          <span className="tw-w-40" />
          <Typo
            className="tw-whitespace-nowrap"
            variant="labelForStatus"
            fontWeight={500}
            fontStyle="italic"
            color={$primaryNavy}
          >
            {t('invoiceDetails.billing.bident')}
          </Typo>
          <Typo
            className="tw-whitespace-nowrap"
            variant="labelForStatus"
            fontWeight={500}
            fontStyle="normal"
            color={$primaryNavy}
          >
            {t(SUBTOTAL_VALUE_KEY, { value: invoice?.bident_price })}
          </Typo>
        </div>
      </InformationBox>
    </div>
  ) : null;
}

/* istanbul ignore next */
export const invoicesCols: GridColDef[] = [
  {
    field: 'payment_method',
    headerName: 'METHOD',
    valueGetter: /* istanbul ignore next */ (value: string) => `${value?.toUpperCase() || '-'}`,
    flex: 0.75,
  },
  {
    field: 'invoice_id',
    headerName: 'INV. NUM',
    flex: 1,
  },
  {
    field: 'payment_status',
    renderHeader: () => columnRenderHeader('PAYMENT', 'STATUS'),
    valueGetter: /* istanbul ignore next */ (value: string) => `${value?.toUpperCase() || '-'}`,
    flex: 1,
  },
  {
    field: 'licensee_number',
    headerName: 'LICENSEE',
    flex: 1,
  },
  {
    field: 'set_id',
    headerName: 'SET #',
    flex: 1,
  },
  {
    field: 'start_date',
    renderHeader: () => columnRenderHeader('START', 'DATE'),
    flex: 1,
  },
  {
    field: 'end_date',
    renderHeader: () => columnRenderHeader('END', 'DATE'),
    flex: 1,
  },
  {
    field: 'modification_date',
    renderHeader: () => columnRenderHeader('MODIFICATION', 'DATE'),
    flex: 1,
  },
  {
    field: 'creation_date',
    renderHeader: () => columnRenderHeader('CREATION', 'DATE'),
    flex: 1,
  },
  {
    field: 'booking_id',
    headerName: 'BOOKING ID',
    flex: 1,
  },
  {
    field: 'booking_status',
    headerName: 'BOOKING STATUS',
    flex: 1,
  },
  {
    field: 'business_number',
    headerName: 'BUSS NUMBER',
    flex: 1,
  },
  {
    field: 'rental_type',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('RENTAL', 'TYPE'),
    minWidth: 80,
    renderCell: /* istanbul ignore next */ (params: GridRenderCellParams<any, RentalType>) =>
      params.value ? RentalTypeNode(params.value) : '-',
  },
  {
    field: 'set_designation',
    headerName: 'SET DESIGNATION',
    flex: 1,
    sortable: false,
  },
  {
    field: 'po_reference',
    headerName: 'P.O#',
    flex: 1,
  },
  {
    field: 'price',
    headerName: 'PRICE',
    valueGetter: /* istanbul ignore next */ (value: any) =>
      formatPriceLocaleAware(value, Currency.EUR),
    flex: 1,
  },
];
