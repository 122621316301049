import {
  differenceInDays,
  format,
  isBefore,
  parse,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';

export function getDateFormat(date: number | Date, withTime = true): string {
  try {
    return format(date, `dd/MM/yyyy${withTime ? ' HH:mm:ss' : ''}`);
  } catch (_) {
    return null;
  }
}

export function getDateNow(): string {
  return format(new Date(), 'dd/MM/yyyy HH:mm:ss');
}

export function getDateUsingFormat(date: Date, formatString: string): string {
  return format(date, formatString);
}

export function formatDate(date: string, withTime = true): string {
  return getDateFormat(getDateFromString(date), withTime);
}

export function convertToDates(dates: (Date | string)[]): Date[] {
  return dates?.map((date) => (!(date instanceof Date) ? parseISO(date) : date));
}

export function getOldestDate(dates: Date[]): Date {
  const castedDates = convertToDates(dates);
  return castedDates.length === 1
    ? castedDates[0]
    : castedDates.reduce((c, n) => (isBefore(n, c) ? n : c));
}

export function getDateFromString(date: string): Date {
  return new Date(date);
}

export function parseToValidDate(date: string, format = 'dd/MM/yyyy'): Date {
  const parsedDate = parse(date, format, new Date());
  if (!parsedDate) {
    return new Date(date);
  }

  return parsedDate;
}

export function addTimeToDate(date: Date): Date {
  if (!date) return null;

  const now = new Date();

  return setSeconds(setMinutes(setHours(date, now.getHours()), now.getMinutes()), now.getSeconds());
}

export function stringDateComparator(firstDate: string, secondDate: string) {
  const castFirstDate = ![null, undefined, '-'].includes(firstDate)
    ? new Date(firstDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1'))
    : new Date(0);
  const castSecondDate = ![null, undefined, '-'].includes(secondDate)
    ? new Date(secondDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1'))
    : new Date(0);
  castFirstDate.setHours(0, 0, 0, 0);
  castSecondDate.setHours(0, 0, 0, 0);
  return castFirstDate.getTime() - castSecondDate.getTime();
}

export function getDifferenceInDays(startDate: Date, endDate: Date): number {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  return differenceInDays(endDate, startDate);
}

export function getJobTitle(): string {
  const date = new Date();
  const year = date.getFullYear();

  const month = date.getMonth();

  let quarter: string;
  if (month >= 0 && month <= 2) {
    quarter = 'Q1';
  } else if (month >= 3 && month <= 5) {
    quarter = 'Q2';
  } else if (month >= 6 && month <= 8) {
    quarter = 'Q3';
  } else {
    quarter = 'Q4';
  }

  // Generate the title
  return `Create a Job - ${quarter} ${year}`;
}
