import {
  CircleStatus,
  LabelBox,
  Notification,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Check } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLoaderData, useParams } from 'react-router-dom';
import { TypeOf, number, object, string } from 'zod';

import { useAppSelector } from '../../../hooks';
import {
  AxiosSetDetailsManagement,
  SetGaugeModelUI,
  UpdateSetApi,
} from '../../../interfaces/set-creation';
import { Status } from '../../../interfaces/veg';
import { mappingSetContentApi } from '../../../pages/utils';
import { updateSet } from '../../../services/api/set-creation-api';
import { updateSetStatus } from '../../../services/api/veg-api';
import { selectUser } from '../../../services/store/userSlice';
import { $lightBlue, $primaryNavy, $primaryWhite } from '../../../styles/colors';
import {
  REQUIRED_FIELD,
  SET_AVAILABILITY_STATUS_COLOR,
  SetAvailabilityStatus,
  VegWebsiteStatus,
} from '../../../utils';
import {
  getDateFormat,
  getLicenseeName,
  getOldestDate,
  toLabelValues,
} from '../../../utils/functions';
import { DialogChangeStatus } from '../../booking/reservation-details-actions/DialogChangeStatus';
import { GridStatus } from '../../data-grid/utils';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import {
  CtaLoadingButton,
  MenuOptionsProps,
  ScrollToTop,
  SetStateBox,
  VamMoreOptions,
  VamPageTitle,
} from '../../shared';
import Comments from '../../shared/Comments/Comments';
import SetContent from '../SetContent';
import SetInformations from '../SetInformations';
import { GaugeError, updateGauges } from '../utils';

const formSchema = object({
  gauge_location: number({ required_error: REQUIRED_FIELD }),
  comments: string().optional().nullable(),
  calibration_site: number({ required_error: REQUIRED_FIELD }),
});

type FormSchemaType = TypeOf<typeof formSchema>;

export function UpdateSet() {
  const params = useParams();
  const { data } = useLoaderData() as AxiosSetDetailsManagement;
  const currentSetId = parseInt(params['setId'], 10);

  const defaultValues: FormSchemaType = {
    gauge_location: data?.location_id,
    comments: data?.comments,
    calibration_site: data?.calibration_site_id,
  };

  const [websiteStatus, setWebsiteStatus] = React.useState<VegWebsiteStatus>(data?.website_status);
  const [availabilityStatus, setAvailabilityStatus] = React.useState<SetAvailabilityStatus>(
    data?.availability_status
  );
  const { t } = useTranslation('gauge');
  const { t: changeDialogTranslation } = useTranslation('gauge', {
    keyPrefix: 'setDetails.changeStatusDialog',
  });
  const { t: translateCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector(selectUser);
  const [gauges, setGauges] = React.useState<SetGaugeModelUI[]>(
    data?.gauges?.map((item) => ({ ...item, initialSerialNumber: item.serial_number }))
  );
  const [isComplete, setIsComplete] = React.useState<boolean>(data?.is_complete);
  const [openChangeStatus, setOpenChangeStatus] = React.useState<boolean>(false);
  const [nextCalibrationDate, setNextCalibrationDate] = React.useState(
    new Date(data?.next_set_calibration_date)
  );
  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit, formState } = methods;

  /* istanbul ignore next */
  const handleSetChangeWebStatus = (target_status: VegWebsiteStatus) => {
    setWebsiteStatus(target_status);
  };

  /* istanbul ignore next */
  const optionsMenu: MenuOptionsProps[] = [
    {
      label: t('websiteStatus.active'),
      name: VegWebsiteStatus.Active,
      handleItemClick: () => {
        handleSetChangeWebStatus(VegWebsiteStatus.Active);
      },
    },
    {
      label: t('websiteStatus.draft'),
      name: VegWebsiteStatus.Draft,
      handleItemClick: () => {
        handleSetChangeWebStatus(VegWebsiteStatus.Draft);
      },
    },
    {
      label: t('websiteStatus.waiting'),
      name: VegWebsiteStatus.Waiting,
      handleItemClick: () => {
        handleSetChangeWebStatus(VegWebsiteStatus.Waiting);
      },
    },
    {
      label: t('manageBookingDetail.actionsMenu.changeStatus'),
      handleItemClick: () => {
        setOpenChangeStatus(true);
      },
    },
  ];

  const onUpdateSet: SubmitHandler<FormSchemaType> = (data) => {
    const modifiedBy = `${user.lastName} ${user.firstName}`;
    const { gauge_location, comments, calibration_site } = data;

    const dataApi: UpdateSetApi = {
      id: currentSetId,
      set_content: mappingSetContentApi(gauges, modifiedBy),
      location_id: gauge_location,
      next_set_calibration_date: nextCalibrationDate,
      modified_by: modifiedBy,
      comments,
      website_status: websiteStatus,
      is_complete: isComplete,
      calibration_site_id: calibration_site,
    };

    /* istanbul ignore next */
    updateSet(params['setId'], dataApi)
      .then(() => {
        enqueueSnackbar(translateCommon('notification.success'), {
          preventDuplicate: true,
          variant: 'success',
        });
        // The initialSerialNumber is now the new one because it has been updated
        setGauges(gauges.map((gauge) => ({ ...gauge, initialSerialNumber: gauge.serial_number })));
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(translateCommon('notification.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
      });
  };

  /* istanbul ignore next */
  const updateGaugesGrid = (row: SetGaugeModelUI) => {
    const newGaugeModels = gauges.map((item) => (item.id === row.id ? row : item));
    setGauges(updateGauges(newGaugeModels, true));
    setIsComplete(
      !newGaugeModels.find((item) => item.is_present === false && item.is_gauge_required === true)
    );
    const calibrationDates = newGaugeModels
      .map((item) => item?.next_calibration_date)
      .filter((next_calibration_date) => !!next_calibration_date);

    if (calibrationDates?.length) {
      const dateObjects = calibrationDates.map((date) => new Date(date));
      const lastDate = getOldestDate(dateObjects);
      setNextCalibrationDate(lastDate);
    } else {
      setNextCalibrationDate(null);
    }
  };

  const errorGauge = gauges.find((gauge) => gauge.grid_status === GridStatus.KO);

  return (
    <ContainerOutlet>
      <DialogChangeStatus
        id={data.id}
        licensee_number={user.licenseeNumber}
        currentStatus={data.availability_status as Status}
        initialTargetStatusList={toLabelValues(
          SetAvailabilityStatus,
          'setDetails.changeStatusDialog.status.'
        )}
        openChangeStatusDialog={openChangeStatus}
        setChangeStatusDialog={setOpenChangeStatus}
        translation={changeDialogTranslation}
        submitUpdateStatus={updateSetStatus}
        updateActualStatus={setAvailabilityStatus}
      />
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('setDetails.tag')}
              title={t('setDetails.title')}
              breadcrumbRoutes={[
                {
                  breadcrumb: 'Sets management',
                  path: '/admin/vam-easy-gauge/set-creation/sets-management',
                },
                {
                  breadcrumb: 'Set update',
                  path: '#',
                },
              ]}
              detailsLabelBox={[
                {
                  label: params['setId'],
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center">
            {errorGauge && (
              <>
                <ScrollToTop />
                <Notification
                  title={t('setCreation.notification.title', { context: errorGauge.error })}
                  description={
                    <Trans components={{ bold: <strong /> }}>
                      {t('setCreation.notification.description', {
                        context: errorGauge.error,
                        value:
                          errorGauge.error === GaugeError.EXISTS ||
                          errorGauge.error === GaugeError.DUPLICATED_SERIAL_NUMBER
                            ? errorGauge.serial_number?.toUpperCase()
                            : errorGauge.designation_vam?.toUpperCase(),
                      })}
                    </Trans>
                  }
                  severity="error"
                />
              </>
            )}
            <Typo
              variant="body2"
              data-testid="set-details-availability-status"
              color={SET_AVAILABILITY_STATUS_COLOR[availabilityStatus]}
              fontWeight={500}
              className="text-ellipsis"
            >
              {changeDialogTranslation(`status.${availabilityStatus}`)}
            </Typo>
            {[
              SetAvailabilityStatus.AtLicensee,
              SetAvailabilityStatus.Reserved,
              SetAvailabilityStatus.Validated,
            ].includes(availabilityStatus) ? (
              <LabelBox
                background={$primaryNavy}
                color={$primaryWhite}
                label={getLicenseeName(data?.licensee_number, data?.licensee_name)}
                width="auto"
                height="24px"
                padding="4px 16px"
              />
            ) : null}

            <SetStateBox
              isComplete={isComplete}
              nextCalibrationDate={new Date(nextCalibrationDate)}
            />
            <CircleStatus
              variant={websiteStatus}
              label={translateCommon(`websiteStatus.${websiteStatus}`)}
            />
            <VamMoreOptions
              data-testid="vam-menu-detail-actions"
              options={optionsMenu.filter((item) => item.name !== websiteStatus)}
            />
            <CtaLoadingButton
              buttonText={t('setDetails.updateButton')}
              data-testid="submit-update-set-btn"
              type="submit"
              variant="cta"
              startIcon={<Check />}
              onClick={handleSubmit(onUpdateSet)}
              disabled={!formState.isValid || !!errorGauge || !nextCalibrationDate}
            />
          </Stack>
        </Stack>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: $lightBlue,
          }}
        >
          <Grid item xs={12}>
            <SetInformations setInformationsDetails={data} />
          </Grid>
          <Grid item xs={12}>
            <SetContent
              gaugeModels={gauges}
              nextCalibrationDate={
                nextCalibrationDate ? getDateFormat(new Date(nextCalibrationDate), false) : '-'
              }
              updateGaugesGrid={updateGaugesGrid}
              with_damage_column={true}
              currentSetId={currentSetId}
            />
          </Grid>
          <Grid item xs={12}>
            <Comments />
          </Grid>
        </Grid>
      </FormProvider>
    </ContainerOutlet>
  );
}

export default UpdateSet;
