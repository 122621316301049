import { AxiosResponse } from 'axios';

import {
  AxiosSetModelManagementDetails,
  DistinctConnectionApi,
  GaugeModelApi,
  GaugeModelsParams,
  GaugeModelsResults,
  SaveSetModelApi,
  SetModelConnectionFilter,
  SetModelManagementDetails,
  SetModelResult,
  SetModelsManagementParams,
  SetModelsManagementResult,
  TotalWeightParams,
  UpdateSetModelApi,
} from '../../interfaces/set-model';
import { VegWebsiteStatus } from '../../utils/constants/booking';
import { endpoints } from '../../utils/constants/endpoints/veg-endpoints';
import { vegHttp, vegHttpWithSpinner } from '../axios-services/http-veg';

/**
 * post save new gauge model
 * @param data GaugeModelApi
 * @returns Promise id number
 */
export function saveGaugeModel(data: GaugeModelApi): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.gauge_model, data);
}

/**
 * post get gauge models
 * @param data GaugeModelParams
 * @return Promise GaugeModelsResults
 */
export function getGaugeModels(
  data: GaugeModelsParams
): Promise<AxiosResponse<GaugeModelsResults, any>> {
  return vegHttp.post<GaugeModelsResults>(endpoints.gauge_models, data);
}

/**
 * post save new set model
 * @param data SaveSetModelApi
 * @returns Promise id number
 */
export function saveSetModel(data: SaveSetModelApi): Promise<AxiosResponse<SetModelResult, any>> {
  return vegHttpWithSpinner.post<SetModelResult>(endpoints.set_model, data);
}

/**
 * fetch connection by apply filter if exist
 * @param params?: SetModelConnectionFilter
 * @returns DistinctConnectionApi
 */
export function getDistinctSetModelsFilter(
  params: SetModelConnectionFilter
): Promise<AxiosResponse<DistinctConnectionApi, any>> {
  // fetch only active set model
  const distinctParams = { status: VegWebsiteStatus.Active, ...params };
  return vegHttpWithSpinner.post<DistinctConnectionApi>(
    endpoints.distinct_set_models,
    distinctParams
  );
}

/**
 * fetch set models management by apply filter if exist
 * @param params?: SetModelsManagementParams
 * @returns DistinctConnectionApi
 */
export function getSetModelsManagement(
  params: SetModelsManagementParams
): Promise<AxiosResponse<SetModelsManagementResult, any>> {
  return vegHttp.post<SetModelsManagementResult>(endpoints.set_models, params);
}

/**
 * Get Detail of set model by set_model_id
 * @param set_model_id string
 * @returns Promise SetModelManagementDetails
 */
export function getSetModelManagementDetails(
  set_model_id: string
): Promise<AxiosSetModelManagementDetails> {
  return vegHttpWithSpinner.get<SetModelManagementDetails>(
    endpoints.set_models_by_id(set_model_id)
  );
}

/**
 * Update set model
 * @param set_model_id string
 * @param data UpdateSetModelApi
 * @returns Promise id number
 */
export function updateSetModel(
  set_model_id: string,
  data: UpdateSetModelApi
): Promise<AxiosResponse<SetModelResult, any>> {
  return vegHttpWithSpinner.put<SetModelResult>(endpoints.set_models_by_id(set_model_id), data);
}

/**
 * Check if set model has been linked by at least one set
 * @param set_model_id string
 * @returns Promise boolean
 */
export function checkSetModelLinked(set_model_id: string): Promise<AxiosResponse<boolean, any>> {
  return vegHttpWithSpinner.get<boolean>(endpoints.set_exists_by_set_model(set_model_id));
}

/**
 * Get set model total_weight
 * @param params TotalWeightParams
 * @returns Promise total_weight number
 */
export function calculateSetModelTotalWeight(
  params: TotalWeightParams
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.set_model_total_weight, params);
}

/**
 * Export set models results on csv file by apply filter if exist
 * @returns Blob
 * @param params
 */
export function exportCsvSetModels(
  params: SetModelsManagementParams
): Promise<AxiosResponse<Blob, any>> {
  return vegHttp.post<Blob>(endpoints.export_csv_set_models, params, {
    responseType: 'blob',
  });
}
